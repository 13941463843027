import React from "react"
import { Helmet } from "react-helmet"

export function Redirection(props) {
  const URL = `0;url=${props.url}`
  document.location.href = props.url
  return (
    <Helmet>
      <meta http-equiv="refresh" content={URL} />
    </Helmet>
  )
}

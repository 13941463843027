import React from "react"
import styled from "styled-components"

const SpinnerWrapper = styled.aside`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  ${props => props.fullPage && `
    display: block;
    margin: 100px auto 200px;
  `}

  div {
    position: absolute;
    border: 4px solid #02363d;
    opacity: 1;
    border-radius: 50%;
    animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;

    &:nth-child(2) {
      animation-delay: -0.5s;
    }
  }

  @keyframes ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }
`

export default function Spinner({ fullPage=false }) {
  return (
    <SpinnerWrapper fullPage={fullPage}>
      <div />
      <div />
    </SpinnerWrapper>
  )
}

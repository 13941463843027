import ReactGA from "react-ga"
import { GA_ID } from "src/config"

class GA {
  static load() {
    ReactGA.initialize(GA_ID)
  }

  /*
   * GA PageView
   * */
  static view = (path) => {
    ReactGA.pageview(path || window.location.pathname + window.location.search)
  }

  /*
   * GA Set Experiment
   * */
  static set = (...params) => {
    window.ga(...params)
  }

  /*
   * GA Event
   * */
  static event = (e) => {
    ReactGA.event(GA.listOfEvents[e])
  }

  /*
   * GA ZIPCODE Event
   * */
  static zipcodeNotFound = (e, value) => {
    let event = GA.listOfEvents[e]
    event.value = value
    ReactGA.event(event)
  }
}

GA.PageLoaded = "PageLoaded"
GA.CompletedFirstPage = "CompletedFirstPage"
GA.CompletedSecondPage = "CompletedSecondPage"
GA.ZipcodeNotFound = "ZipcodeNotFound"
GA.ZipcodeFound = "ZipcodeFound"
GA.RegistrationFailed = "RegistrationFailed"
GA.RegistrationCompleted = "RegistrationCompleted"
GA.CarouselClicked = "CarouselClicked"
GA.listOfEvents = {
  [GA.PageLoaded]: {
    category: "Registration",
    action: "Page Loaded",
  },
  [GA.CompletedFirstPage]: {
    category: "Registration",
    action: "User completed the first page of the application",
  },
  [GA.CompletedSecondPage]: {
    category: "Registration",
    action: "User completed the second page of the application",
  },
  [GA.ZipcodeNotFound]: {
    category: "Registration",
    action: "Postal code found on Google",
  },
  [GA.ZipcodeFound]: {
    category: "Registration",
    action: "Invalid Postal code",
    value: ""
  },
  [GA.RegistrationFailed]: {
    category: "Registration",
    action: "Registration Failed",
  },
  [GA.RegistrationCompleted]: {
    category: "Registration",
    action: "Registration Completed",
  },
  [GA.CarouselClicked]: {
    category: "Registration",
    action: "Carousel Clicked",
  }
}

export default GA

/* eslint-disable no-console */

/**
 * analytics
 * Our single place for connecting with 3rd party analytics systems.
 * Initially just connecting with Segment.
 *
 * @example
 * import React from 'react';
 * import analytics from 'src/core/analytics';
 *
 * export default class MyComponent extends React.PureComponent {
 *   componentDidMount() {
 *     analytics.log('view', 'my_component__view', { custom_param_one: 1, custom_param_two: 2 });
 *   }
 *   render() {
 *     return <div><button onClick={() => {analytics.log('action', 'my_button__clicked')}}>My Button</button></div>;
 *   }
 * }
 */

import { ENV_DEV, ENV_STAGE, ENV_PROD, ENV } from "../config"

const SEGMENT_ID =
    process.env.REACT_APP_SEGMENT_ID || "7Ex0Cf2ZNiRQa6HpGKbnPhMTgiC8Owb2"

const LOG_DEV_TO_SEGMENT = true
const LOG_EVENTS_TO_SEGMENT =
    ENV === ENV_PROD ||
    ENV === ENV_STAGE ||
    (ENV === ENV_DEV && LOG_DEV_TO_SEGMENT)

if (ENV === ENV_DEV || ENV === ENV_STAGE) {
    console.log("LOG_EVENTS_TO_SEGMENT:", LOG_EVENTS_TO_SEGMENT)
}

const SEGMENT_MOCK = {
    init: () => {
        //
    },
    identify: () => {
        //
    },
    page: () => {
        //
    },
    track: () => {
        //
    },
}
const GET_SEGMENT = () =>
    window && window.analytics ? window.analytics : SEGMENT_MOCK

const LOG_TO_CONSOLE =
    (ENV === ENV_DEV || ENV === ENV_STAGE) && GET_SEGMENT() !== SEGMENT_MOCK

const analytics = () => {
    const init = () => {
        if (LOG_EVENTS_TO_SEGMENT) {
            // $FlowFixMe
            GET_SEGMENT().load(SEGMENT_ID)
        }

        if (LOG_TO_CONSOLE) {
            console.log("[analytics]: init")
        }
    }

    const identify = (uid, traits) => {
        if (LOG_EVENTS_TO_SEGMENT) {
            // $FlowFixMe
            GET_SEGMENT().identify(uid, traits)
        }

        if (LOG_TO_CONSOLE) {
            console.log("[analytics]: identify", uid, traits)
        }
    }

    const reset = () => {
        if (LOG_EVENTS_TO_SEGMENT) {
            // $FlowFixMe
            GET_SEGMENT().reset()
        }

        if (LOG_TO_CONSOLE) {
            console.log("[analytics]: reset")
        }
    }

    const log = (type, name, properties = null) => {
        if (name !== "jyver_application_done__view") return // only tracking this event with Segment for now
        // TODO: Remove above block if we go back to sending all events to Segment
        const newProps = Object.assign({}, properties) // clearer for logging, so we're not always pointing to same object in memory

        if (LOG_EVENTS_TO_SEGMENT) {
            if (type.toLowerCase() === "view") {
                // $FlowFixMe'
                GET_SEGMENT().page(name, newProps)
            } else {
                // $FlowFixMe
                GET_SEGMENT().track(name, newProps)
            }
        }

        if (LOG_TO_CONSOLE) {
            console.log("[analytics]: log", type, name, newProps)
        }
    }

    return {
        init,
        identify,
        reset,
        log,
    }
}

export default analytics()

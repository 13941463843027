import React, { Component } from "react"
import GA from "src/services/ga"

class GAWrapper extends Component {
  constructor(props) {
    super(props)
    this.view()
  }

  componentDidUpdate(){
    this.view()
  }

  view(){
    GA.view()
  }

  render() {
    const { children } = this.props
    return <React.Fragment>{children}</React.Fragment>
  }
}

export default GAWrapper

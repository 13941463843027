export const breakpoints: Record<string, string> = {
    xs: "800px",
    md: "960px",
    xl: "1024px",
    // There are some cases where we use specific media queries.
    width300: "300px",
    width350: "350px",
    width400: "400px",
    width450: "400px",
    width500: "500px",
    width550: "550px",
    width600: "600px",
    width700: "700px",
    width1250: "1250px",
    width1110: "1110px",
}
export const theme = {
    main: "#02363D",
    white: "white",
    red: "#E54360",
    platinum: "#A9C3CC",
    turquois: "#29D0AE",
    darkRed: "#671D33",
    darkGray: "#EDEDED",
    iceBlue: "#E0EAED",
    successBgColor: "#95E4B6",
    gray: "#F8F8F8",
    dark: "#4C5556",
    inputPlaceholderColor: "#828282",
    green: "#1D7874",
    disabled: "#E0E0E0",
    buttonFocus: "#AC2B42",
    errorMessage: "#FFB1C3",
    ratingErrorMessage: "#FD184C",
    maintenanceBarBgColor: "#F5DD94",
    headerZIndex: 9999,
    maxWidth: 1440,
    onboardingFormBorderRadius: 8,
    fatButtonBorderRadius: 29,
    buttonBorderRadius: 4,
    inputBorderRadius: 4,
    cardBorderRadius: 12,
    typography: {
        header: {
            xl: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "55px",
                    lineHeight: "114.9%",
                    letterSpacing: "0.06em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "40px",
                    lineHeight: "114.9%",
                    letterSpacing: "0.06em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "25px",
                    lineHeight: "114.9%",
                    letterSpacing: "0.04em",
                },
            },
            lg: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "37px",
                    lineHeight: "108.9%",
                    letterSpacing: "0.06em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "30px",
                    lineHeight: "108.9%",
                    letterSpacing: "0.06em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "22px",
                    lineHeight: "122%",
                    letterSpacing: "0.04em",
                },
            },
            md: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "24px",
                    lineHeight: "121%",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontWeight: 600,
                    fontSize: "22px",
                },
                mobile: {
                    fontWeight: 600,
                    fontSize: "20px",
                },
            },
            sm: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "22px",
                    lineHeight: "121%",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "19px",
                    lineHeight: "108.9%",
                    letterSpacing: "0.06em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "16px",
                    lineHeight: "122%",
                    letterSpacing: "0.04em",
                },
            },
            xs: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "16px",
                    lineHeight: "121%",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "16px",
                    lineHeight: "108.9%",
                    letterSpacing: "0.06em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 900,
                    fontSize: "16px",
                    lineHeight: "122%",
                    letterSpacing: "0.04em",
                },
            },
        },
        paragraph: {
            xl: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "23px",
                    lineHeight: "147%",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "18px",
                    lineHeight: "147%",
                    letterSpacing: "0.04em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "15px",
                    lineHeight: "147%",
                    letterSpacing: "0.04em",
                },
            },
            lg: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
            },
            md: {},
            sm: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 200,
                    fontSize: "16px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 200,
                    fontSize: "16px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 200,
                    fontSize: "16px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
            },
            xs: {
                desktop: {
                    fontStyle: "normal",
                    fontWeight: 200,
                    fontSize: "14px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
                tablet: {
                    fontStyle: "normal",
                    fontWeight: 200,
                    fontSize: "14px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
                mobile: {
                    fontStyle: "normal",
                    fontWeight: 200,
                    fontSize: "14px",
                    lineHeight: "1.4em",
                    letterSpacing: "0.04em",
                },
            },
        },
    },
}

import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { Suspense } from "react"
import { Helmet } from "react-helmet"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import ScrollToTop from "src/core/ScrollToTop"
import { ThemeProvider } from "styled-components/macro"
import "src/styles/global-styles.scss"
import GAWrapper from "src/components/GA"
import Spinner from "src/components/Spinner"
import GA from "src/services/ga"
import { Redirection } from "src/utils/Redirection"
import source from "sourcebuster"
// import Home from "src/containers/Home";
import analytics from "src/core/analytics"
import { theme } from "src/theme"
// const HomePage = React.lazy(() => import("src/containers/HomePage"));
const Applink = React.lazy(() => import("src/containers/Applink"))
const AppleAppSiteAssociation = React.lazy(() => import("src/containers/AppleAppSiteAssociation"))
const Application = React.lazy(() => import("src/containers/Application"))
const OnboardingRedirection = React.lazy(() =>
    import("src/containers/OnboardingRedirection")
)
const Home = React.lazy(() => import("src/containers/Home"))
// const Coronavirus = React.lazy(() => import("src/containers/Coronavirus"));
const NotFoundPage = React.lazy(() => import("src/containers/NotFound"))
const Contact = React.lazy(() => import("src/containers/Contact"))
const Careers = React.lazy(() => import("src/containers/Careers"))
const StripeSession = React.lazy(() => import("src/containers/StripeSession"))
// const About = React.lazy(() => import("src/containers/About"));
const Rating = React.lazy(() => import("src/containers/Rating/Rating"))
const News = React.lazy(() => import("src/containers/News"))
// const Onboarding = React.lazy(() => import("src/containers/Onboarding"));
const Privacy = React.lazy(() => import("src/containers/Privacy"))
const PrivacyCAApplicant = React.lazy(() =>
    import("src/containers/PrivacyCAApplicant")
)
const OnboardingSsn = React.lazy(() =>
    import("src/containers/OnboardingSsn")
)
const OnboardingBackgroundCheck = React.lazy(() =>
    import("src/containers/OnboardingBackgroundCheck")
)

const BackgroundCheckDoc = React.lazy(() =>
    import("src/containers/BackgroundCheckDoc")
)
const OnboardingHoldTight = React.lazy(() =>
    import("src/containers/OnboardingHoldTight")
)

const OnboardingAllDone = React.lazy(() =>
    import("src/containers/OnboardingAllDone")
)
const Terms = React.lazy(() => import("src/containers/Terms"))
const Msa = React.lazy(() => import("src/containers/Msa"))
const ChangePassword = React.lazy(() => import("src/containers/ChangePassword"))
const BrandsAndRetails = React.lazy(() =>
    import("src/containers/BrandsAndRetailers")
)

GA.load()
source.init({
    session_length: 1,
    lifetime: 1,
})

const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL

const App = () => {
    analytics.init()
    analytics.reset()
    return (
        <ThemeProvider theme={theme}>
            <div className="app-wrapper">
                <Helmet titleTemplate="Jyve! %s" defaultTitle="Jyve!" />
                <Router>
                    <GAWrapper>
                        <ScrollToTop>
                            <Suspense fallback={<Spinner fullPage />}>
                                <Switch>
                                    {/*<Route exact path="/coronavirus" component={Coronavirus} />*/}
                                    {/* <Route exact path="/about" component={About} /> */}
                                    <Route
                                        exact
                                        path="/careers"
                                        component={Careers}
                                    />
                                    <Route
                                        exact
                                        path="/privacy"
                                        component={Privacy}
                                    />
                                    <Route
                                        exact
                                        path="/terms"
                                        render={() => <Terms />}
                                    />
                                     <Route
                                        exact
                                        path="/msa"
                                        render={() => <Msa />}
                                    />
                                    <Route
                                        exact
                                        path="/`privacy-ca-applicant"
                                        component={PrivacyCAApplicant}
                                    />
                                    <Route
                                        exact
                                        path="/contact"
                                        component={Contact}
                                    />
                                    <Route
                                        exact
                                        path="/brands-and-retailers"
                                        component={BrandsAndRetails}
                                    />
                                    {/*<Redirect exact from="/how-it-works" to="/brands-and-retailers" />*/}
                                    {/*<Redirect exact from="/what-we-do" to="/brands-and-retailers" />*/}
                                    <Route
                                        exact
                                        path="/"
                                        component={Home}
                                    />
                                    <Route
                                        exact
                                        path="/onboarding/ssn"
                                        component={OnboardingSsn}
                                    />
                                    <Route
                                        exact
                                        path="/backgroundcheck/doc"
                                        component={BackgroundCheckDoc}
                                    />
                                     <Route
                                        exact
                                        path="/onboarding/hold-tight"
                                        component={OnboardingHoldTight}
                                    />
                                    <Route
                                        exact
                                        path="/onboarding/background-check"
                                        component={OnboardingBackgroundCheck}
                                    />
                                     <Route
                                        exact
                                        path="/onboarding/alldone"
                                        component={OnboardingAllDone}
                                    />
                                    {/*<Route exact path="/homepage" component={Home} />*/}
                                    <Route
                                        exact
                                        path="/support"
                                        component={() =>
                                            Redirection({
                                                url: SUPPORT_URL,
                                            })
                                        }
                                    />
                                    <Route
                                        exact
                                        path="/help"
                                        component={() =>
                                            Redirection({
                                                url: SUPPORT_URL,
                                            })
                                        }
                                    />

                                    <Route
                                        exact
                                        path="/applink"
                                        component={Applink}
                                    />
                                    <Route
                                        exact
                                        path="/news/jyve-named-2020-gartner-cool-vendor-in-retail"
                                        component={News}
                                    />
                                    {/*<Route
                                        exact
                                        path="/become-a-jyver"
                                        component={Application}
                                    />*/}
                                    <Route
                                        exact
                                        path="/onboarding-reload"
                                        component={OnboardingRedirection}
                                    />
                                     <Route
                                        exact
                                        path="/stripe-session"
                                        component={StripeSession}
                                    />
                                    <Route path="/apple-app-site-association" component={AppleAppSiteAssociation} />
                                    <Route
                                        exact
                                        path="/r/:referralCode"
                                        component={Application}
                                    />
                                    <Route
                                        path="/rating/:request_id"
                                        component={Rating}
                                    />
                                    <Route
                                        path="/s/change"
                                        component={ChangePassword}
                                    />
                                    <Route path="" component={NotFoundPage} />
                                </Switch>
                            </Suspense>
                        </ScrollToTop>
                    </GAWrapper>
                </Router>
            </div>
        </ThemeProvider>
    )
}

export default App

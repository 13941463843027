export const ENV_DEV = "development"
export const ENV_PROD = "production"
export const ENV_STAGE = "stage"
export const ENV = process.env.REACT_APP_ENV_BUILD // need REACT_APP_ENV_BUILD, since CRA build always sets NODE_ENV to 'production'
export const EXPORT_IS_PROD = (a, b) => (ENV !== ENV_PROD ? a : b)

export const GA_ID = "UA-115632426-2"
export const GA_EXP_ID = "RZbm5FmXRdiqtqZ5jOauug"
export const FB_ID = "1378220048916836"
export const JYVE_INTERVAL_ZIPCODE = "00000"

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

export const PATH = {
    rating: {
        rate: "rating_requests/rate/",
        rating_details: "rating_requests/",
    },
    application_form: "account/onboarding/",
    ssn_form: "account/onboarding/run-user-background-check/",
    healthCheck: "health/",
    password: {
        reset: {
            confirm: "account/password/reset/confirm/",
        },
    },
    referral_code_validation: "verify-code/",
}

import GA from "./base"

export default class Memory extends GA {

  static load() {
    console.log("Google Analytics Initilization : UA-115632426-2")
  }

  static view = (path) => {
    console.log(`Google Analytics Send pageView : ${path || window.location.pathname + window.location.search}`)
  }

  static set = (key, value, expId) => {
    console.log(`Google Analytics Send set : ${key}, ${value}, ${expId}`)
  }

  static event = (e) => {
    console.log(`Google Analytics Send event : ${e}`)
  }

  static zipcodeNotFound = (e, value) => {
    let event = {
      category: "Registration",
      action: "Invalid Postal code",
      value: ""
    }
    event.value = value
    console.log(event)
  }
}
